import React from 'react';
import bravoBowlsLogo from '../assets/bravo_bowls_logo.png';
import bravoBowlsImage from '../assets/bravo_bowls_image.png';

const LandingPage = () => {
  return (
    <div className="min-h-screen flex flex-col bg-black sm:bg-[#FDF6EA]">
      <header className="p-4 sm:h-36 flex justify-between items-center sm:px-16 relative z-10">
        <div className="text-[#FFC529] text-xl sm:text-2xl font-bold">BRAVO BOWLS</div>
        <img
          src={bravoBowlsLogo}
          alt="Bravo Bowls Logo"
          className="w-10 h-10 sm:w-24 sm:h-24 object-contain"
        />
        <button className="px-4 py-2 sm:px-6 sm:py-2 border-4 border-[#FFC529] bg-black text-[#FFC529] sm:text-black sm:bg-transparent text-sm sm:text-base font-bold rounded-full hover:bg-[#FFC529] hover:text-black sm:hover:text-white transition-colors duration-300">
          Coming Soon
        </button>
      </header>
      
      <main className="flex-grow bg-[#FFC529] flex flex-col sm:flex-row justify-center items-center p-4 sm:p-8">
        <div className="w-full max-w-md sm:max-w-5xl">
          <div className="relative">
            <img
              src={bravoBowlsImage}
              alt="Bravo Bowls Store"
              className="w-full rounded-3xl shadow-lg"
            />
          </div>
        </div>
      </main>
      
      <footer className="bg-[#FDF6EA] h-16 sm:h-20 flex justify-center items-center">
        <img
          src={bravoBowlsLogo}
          alt="Bravo Bowls Logo"
          className="w-10 h-10 sm:w-12 sm:h-12 object-contain"
        />
      </footer>
    </div>
  );
};

export default LandingPage;